const VP_CLIENT_ID = 'a00e1368c32c471cb7e2ba0defd99a1a'
const VP_CONNECTION_NAME = 'VP-Customers'
const OCI_BASE_URL = 'https://oauth.cimpress.io'
const CODE_VERIFIER = 'CODE_VERIFIER'
const ACCESS_TOKEN_COOKIE_KEY = 'ACCESS_TOKEN_COOKIE_KEY'
const ACCESS_TOKEN_LIFE_TIME_KEY = 'ACCESS_TOKEN_LIFE_TIME_KEY'
const REFRESH_TOKEN_COOKIE_KEY = 'REFRESH_TOKEN_COOKIE_KEY'
const ACCESS_TOKEN_TYPE_COOKIE_KEY = 'ACCESS_TOKEN_TYPE_COOKIE_KEY'
const TOKEN_DETAILS_STORAGE_KEY = 'TOKEN_DETAILS_STORAGE_KEY'
const WAS_TOKEN_CLAIM = 'https://claims.cimpress.io/was'
const ANON_TOKEN_CLAIM = 'https://claims.cimpress.io/is_anonymous'
const CANONICAL_ID_TOKEN_CLAIM = 'https://claims.cimpress.io/canonical_id'
const JWT_TOKEN_TYPE = 'Bearer'

export {
  VP_CLIENT_ID,
  VP_CONNECTION_NAME,
  OCI_BASE_URL,
  CODE_VERIFIER,
  ACCESS_TOKEN_COOKIE_KEY,
  REFRESH_TOKEN_COOKIE_KEY,
  ACCESS_TOKEN_TYPE_COOKIE_KEY,
  TOKEN_DETAILS_STORAGE_KEY,
  ACCESS_TOKEN_LIFE_TIME_KEY,
  WAS_TOKEN_CLAIM,
  ANON_TOKEN_CLAIM,
  CANONICAL_ID_TOKEN_CLAIM,
  JWT_TOKEN_TYPE
}
