import {
  registerTokenStorage,
} from '@cimpress-technology/authfront-sdk-core'

import { CookieTokenStorage } from './utils/storage'

import type {
  UserSignInPayload, UserSignOutPayload, SESSION_TYPE, UserTokenContext, UserIdentityEvent, AuthorizationInfo,
  ErrorAndResultCallbackFn
} from '../common/types'

import { OAUTH_SCOPES } from '../common/types'

import { AuthService, Config } from './auth'

registerTokenStorage(new CookieTokenStorage())

export {
  AuthService,
  OAUTH_SCOPES,
  type Config,
  type UserSignInPayload,
  type UserSignOutPayload,
  type SESSION_TYPE,
  type UserTokenContext,
  type UserIdentityEvent,
  type AuthorizationInfo,
  type ErrorAndResultCallbackFn,
}
