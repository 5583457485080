export function generateFinalRedirectURL (pathName: string = ''): string {
  const location = getWindowLocation()
  return location.origin + '/' + pathName.replace(/^\//, '')
}

export function getWindow (): any {
  return window
}

export function getWindowLocation (): Location {
  return getWindow().location
}
