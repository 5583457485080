import {
  signIn,
  signOut as logout,
  getToken,
  isSignedIn,
  getIdentity,
  getAuthorization,
  getAuthorizationHeader,
  getCanonicalId,
  getRecentAnonymousIds,
  registerAuthConfigWithOptions,
  getEventTargetInstance,
  type TokenAdditionalInfo
} from '@cimpress-technology/authfront-sdk-core'

import { TOKEN_DETAILS_STORAGE_KEY, VP_CLIENT_ID, OCI_BASE_URL, VP_CONNECTION_NAME } from '../common/contants'

import type { UserSignInPayload, UserSignOutPayload, AuthorizationInfo, UserIdentityEvent, ErrorAndResultCallbackFn } from '../common/types'

import { generateFinalRedirectURL } from './utils/hepler'

type Config = { clientId?: string, oauthBaseURL?: string, tokenAdditionalInfo: TokenAdditionalInfo }

class AuthService {
  private static readonly eventTargetInstance = getEventTargetInstance()

  constructor (authObject: Config, callback?: ErrorAndResultCallbackFn<UserIdentityEvent>) {
    const { clientId, oauthBaseURL, tokenAdditionalInfo } = authObject
    localStorage.setItem(TOKEN_DETAILS_STORAGE_KEY, JSON.stringify(tokenAdditionalInfo))
    registerAuthConfigWithOptions(
      {
        clientId: clientId || VP_CLIENT_ID,
        oauthBaseURL: oauthBaseURL || OCI_BASE_URL,
        useBuiltInCallback: false,
        generateAnonIdentity: true,
        storeRefreshTokenAsCookie: true,
        extendBrowserSession: true
      }, callback)
  }

  isSignedIn = (): boolean => isSignedIn()

  getToken = (): string | undefined => getToken()

  getTokenType = (): string | undefined => getAuthorizationHeader()

  getCanonicalId = (): string | undefined => getCanonicalId()

  getProfile = (): object | undefined => {
    const identity = this.getIdentity()
    return identity?.profile
  }

  getIdentity = (): UserIdentityEvent | undefined => getIdentity()

  signIn = (option: UserSignInPayload): void => {
    return signIn({ ...option, connection: VP_CONNECTION_NAME, hostedCallbackPageURL: generateFinalRedirectURL(option.postLoginRedirectToPage) })
  }

  logout = (option: UserSignOutPayload): void => {
    logout(option)
  }

  getAuthorization = (): AuthorizationInfo | undefined => getAuthorization()

  onUserIdentityUpdate = (callback: (result?: any) => void): void => {
    if (typeof callback !== 'function') { return }
    AuthService.eventTargetInstance.addEventListener('userIdentity', (e: any) => callback(e.detail))
  }

  getRecentAnonymousIds = (): string[] | undefined => getRecentAnonymousIds()
}

export { AuthService, type Config }
