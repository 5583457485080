import {
  TokenStorage,
  type TokenAdditionalInfo,
} from '@cimpress-technology/authfront-sdk-core'

import {
  ACCESS_TOKEN_COOKIE_KEY,
  REFRESH_TOKEN_COOKIE_KEY,
  ACCESS_TOKEN_TYPE_COOKIE_KEY,
  TOKEN_DETAILS_STORAGE_KEY,
  CODE_VERIFIER,
  ACCESS_TOKEN_LIFE_TIME_KEY
} from '../../common/contants'

export class CookieTokenStorage extends TokenStorage {
  private setCookie (name: string, value: string, expiresAt?: Date) {
    let cookieString = `${name}=${encodeURIComponent(value)}; Path=/;`
    if (expiresAt) {
      cookieString += ` Expires=${expiresAt.toUTCString()};`
    }
    document.cookie = cookieString
  }

  private getCookie (name: string): string | undefined {
    const nameEQ = `${name}=`
    const cookies = document.cookie.split(';')
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim()
      if (cookie.indexOf(nameEQ) === 0) {
        return decodeURIComponent(cookie.substring(nameEQ.length))
      }
    }
    return undefined
  }

  private deleteCookie (name: string) {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:00 GMT;`
  }

  saveAccessToken (accessToken: string, expiresAt: Date, tokenType: string): void {
    const epochSeconds = Math.floor(expiresAt.getTime() / 1000)
    console.log('saveRefreshToken', accessToken, expiresAt, expiresAt, epochSeconds)
    this.setCookie(ACCESS_TOKEN_COOKIE_KEY, accessToken, expiresAt)
    this.setCookie(ACCESS_TOKEN_LIFE_TIME_KEY, epochSeconds.toString(), expiresAt)
  }

  retrieveAccessToken (): string | undefined {
    return this.getCookie(ACCESS_TOKEN_COOKIE_KEY)
  }

  deleteAccessToken (): void {
    this.deleteCookie(ACCESS_TOKEN_COOKIE_KEY)
    this.deleteCookie(ACCESS_TOKEN_TYPE_COOKIE_KEY)
  }

  saveRefreshToken (refreshToken: string, expiresAt: Date, saveAsCookie: boolean): void {
    console.log('saveRefreshToken', refreshToken, expiresAt, saveAsCookie)
    this.setCookie(REFRESH_TOKEN_COOKIE_KEY, refreshToken)
  }

  retrieveRefreshToken (): string | undefined {
    return this.getCookie(REFRESH_TOKEN_COOKIE_KEY)
  }

  deleteRefreshToken (): void {
    this.deleteCookie(REFRESH_TOKEN_COOKIE_KEY)
  }

  saveAdditionalInfo (payload: TokenAdditionalInfo): void {
    localStorage.setItem(this.getAdditionalInfoStorageKey(), JSON.stringify(payload))
  }

  retrieveAdditionalInfo (): TokenAdditionalInfo | undefined {
    const stored = localStorage.getItem(this.getAdditionalInfoStorageKey())
    return stored ? JSON.parse(stored) as TokenAdditionalInfo : undefined
  }

  deleteAdditionalInfo (): void {
    localStorage.removeItem(this.getAdditionalInfoStorageKey())
  }

  getAdditionalInfoStorageKey (): string {
    return TOKEN_DETAILS_STORAGE_KEY
  }

  getCodeVerifier (): string {
    return this.getCookie(CODE_VERIFIER) ?? ''
  }

  saveCodeVerifier (codeVerifier: string): void {
    this.setCookie(CODE_VERIFIER, codeVerifier)
  }

  deleteCodeVerifier (): void {
    this.deleteCookie(CODE_VERIFIER)
  }
}
